import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { viewAboutUsEnabled } from '../atoms/Header-Atoms'
import { useRecoilValue } from 'recoil';
import AboutUsShot from '../images/about-us-001.jpg';
import Thumb001 from '../images/thumb-001.png';
import Thumb002 from '../images/thumb-002.png';
import Thumb003 from '../images/thumb-003.png';
import Thumb004 from '../images/thumb-004.png';
import Thumb005 from '../images/thumb-005.png';
import Thumb006 from '../images/thumb-006.png';
import Thumb007 from '../images/thumb-007.png';
import Thumb008 from '../images/thumb-008.png';
import Thumb009 from '../images/thumb-009.png';
import Thumb010 from '../images/thumb-010.png';
import Thumb011 from '../images/thumb-011.png';
import Thumb012 from '../images/thumb-012.png';
import Thumb013 from '../images/thumb-013.png';
import Thumb014 from '../images/thumb-014.png';
import Thumb015 from '../images/thumb-015.png';
import Thumb016 from '../images/thumb-016.png';
import Thumb017 from '../images/thumb-017.png';
import Thumb018 from '../images/thumb-018.png';
import LeftPanelBackground from '../images/about-background-left.svg';

function AboutUs() {
    const enabled = useRecoilValue(viewAboutUsEnabled);

    const ItemLeft = styled(Paper)(({ theme }) => ({
        textAlign: 'left',
        fontSize: 18,
        color: theme.palette.text.secondary,
        minHeight: 860,
        backgroundImage: `url(${LeftPanelBackground})`,
        backgroundSize: "cover"
    }));

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div id="section-1" className="contentModule">
                <Grid container spacing={1}>
                    <Grid item>
                        <ItemLeft>
                            <div className="leftPanel">
                                <h3>About Us</h3>
                                <div className="containerDim">
                                    <img src={AboutUsShot} className="imageWrapLeft" alt="" />
                                    <p>At Bleu Fox Bakery, our mission is to craft the finest breads and pastries using fresh and natural ingredients. You can truly savor the difference in our creations, where achieving the perfect harmony of flavors and textures is our constant aim.</p>
                                    <p>Our journey began with a simple desire: to make better food for ourselves and our loved ones—food free from the artificial additives that have become all too common in today’s food landscape. After baking our first perfect loaf of bread, we were inspired to share that delightful experience with others.</p>
                                    <p>In 2023, we launched our micro bakery as a bake-to-order establishment, ensuring our customers receive the freshest products exactly when they need them. While some of our artisan breads require advance notice due to their lengthy fermentation processes, we also offer a variety of items that can be prepared the same day, depending on the timing of your order.</p>
                                    <p>Although our primary focus is on artisan breads, we also have a delightful selection of sweeter treats, though not cloyingly sweet. We’re constantly experimenting with new offerings, so be sure to check back often to see what’s fresh out of the oven! If you’re looking for something not on our menu or want a special twist on an item, reach out to us—we’re always eager to accommodate your requests.</p>
                                </div>
                                <div className="containerHeight" />
                                <div className="containerDim2">
                                    <img src={Thumb001} className="imageWrapRight" alt="" />
                                    <img src={Thumb002} className="imageWrapMiddle" alt="" />
                                    <img src={Thumb003} className="imageWrapLeft" alt="" />
                                </div>
                                <div className="containerHeight2" />
                                <div className="containerDim2">
                                    <img src={Thumb004} className="imageWrapRight" alt="" />
                                    <img src={Thumb005} className="imageWrapMiddle" alt="" />
                                    <img src={Thumb006} className="imageWrapLeft" alt="" />
                                </div>
                                <div className="containerHeight2" />
                                <div className="containerDim2">
                                    <img src={Thumb007} className="imageWrapRight" alt="" />
                                    <img src={Thumb008} className="imageWrapMiddle" alt="" />
                                    <img src={Thumb009} className="imageWrapLeft" alt="" />
                                </div>
                                <div className="containerHeight2" />
                                <div className="containerDim2">
                                    <img src={Thumb010} className="imageWrapRight" alt="" />
                                    <img src={Thumb011} className="imageWrapMiddle" alt="" />
                                    <img src={Thumb012} className="imageWrapLeft" alt="" />
                                </div>
                                <div className="containerHeight2" />
                                <div className="containerDim2">
                                    <img src={Thumb013} className="imageWrapRight" alt="" />
                                    <img src={Thumb014} className="imageWrapMiddle" alt="" />
                                    <img src={Thumb015} className="imageWrapLeft" alt="" />
                                </div>
                                <div className="containerHeight2" />
                                <div className="containerDim2">
                                    <img src={Thumb016} className="imageWrapRight" alt="" />
                                    <img src={Thumb017} className="imageWrapMiddle" alt="" />
                                    <img src={Thumb018} className="imageWrapLeft" alt="" />
                                </div>
                            </div>
                        </ItemLeft>
                    </Grid>
                </Grid>
            </div>
        </Fade>      
    );
}

export default AboutUs;