import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { viewGetInTouchEnabled } from '../atoms/Header-Atoms'
import { useRecoilValue } from 'recoil';
import GetInTouchShot from '../images/riley-get-in-touch.jpeg';
import LeftPanelBackground from '../images/about-background-left.svg';
import IconEmail from '../images/social-email.svg';

function GetInTouch() {
    const enabled = useRecoilValue(viewGetInTouchEnabled);

    const ItemLeft = styled(Paper)(({ theme }) => ({
        textAlign: 'left',
        fontSize: 18,
        color: theme.palette.text.secondary,
        minHeight: 500,
        backgroundImage: `url(${LeftPanelBackground})`,
        backgroundSize: "cover"
    }));

    const ItemRight = styled(Paper)(({ theme }) => ({
        minHeight: 500,
        backgroundImage: `url(${GetInTouchShot})`,
        backgroundSize: "cover"
    }));

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div id="section-3" className="contentModule">
                <Grid container spacing={2}>
                    <Grid item xs={4} md={6}>
                        <ItemLeft>
                            <div className="leftPanel">
                                <h3>Let's Connect</h3>
                                <p>If you are interested in purchasing our products please shoot us an email with what you would like and we'll be back with you as quick as we can. Thanks!</p>
                                <p>Please note that we are unable to accept online payments at this time or deliver beyond a certain radius. We provide local delivery only for a fee and payment can be made via cash or Venmo. We can do pickups as well. Please provide your delivery address in your order.</p>
                                <p>We currently service the following areas:</p>
                                Flowery Branch
                                <br />Hoschton
                                <br />Braselton
                                <p>Call or text us at: (770) 504-6617</p>
                                <p class="mailPlacement">
                                    <a href="mailto:sales@bleufoxbakery.com" target="_blank">
                                        <img src={IconEmail} width={60} height={60} className="socialIcon" />
                                    </a>
                                </p>
                            </div>
                        </ItemLeft>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <ItemRight></ItemRight>
                    </Grid>
                </Grid>
            </div>
        </Fade>      
    );
}

export default GetInTouch;